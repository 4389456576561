<template>
  <div>
    <!-- Header Alert -->
    <b-alert
      show
      variant="light"
      class="alert alert-custom alert-white alert-shadow fade show gutter-b"
    >
      <div class="alert-icon">
        <span class="svg-icon svg-icon-lg">
          <inline-svg src="/media/svg/icons/Tools/Compass.svg" />
        </span>
      </div>
      <div class="alert-text">
        Daftar <b>Gedung</b> yang ada di Klinik Hayandra
      </div>
      <b-button
        squared
        variant="success"
        @click="$router.push('/masters/buildings/add')"
        v-if="btn"
        >Tambah</b-button
      >
    </b-alert>

    <!-- Component List -->
    <div class="row">
      <div class="col-md-12">
        <Card>
          <template v-slot:body>
            <!-- Filter -->
            <div class="row justify-content-end mt-n3">
              <div class="col-md-3">
                <b-input-group>
                  <template #prepend>
                    <b-input-group-text squared>
                      <b-icon-search></b-icon-search>
                    </b-input-group-text>
                  </template>
                  <b-form-input
                    type="text"
                    v-model="filter.name"
                    placeholder="Cari Berdasar Nama"
                    @keyup="filterByName"
                  ></b-form-input>
                </b-input-group>
              </div>
            </div>

            <!-- Table -->
            <b-table
              striped
              hover
              class="mt-3"
              :items="items"
              :fields="fields"
              :style="'white-space: nowrap'"
              responsive="sm"
            >
              <template #table-colgroup="scope">
                <col
                  v-for="field in scope.fields"
                  :key="field.key"
                  :style="{ width: field.key === 'actions' ? '15%' : '' }"
                />
              </template>
              <template #cell(is_satusehat_upload)="data">
                <b-badge variant="success">{{
                  data.item.is_satusehat_upload == 1 &&
                  data.item.is_need_sync == 0
                    ? "Terkirim"
                    : ""
                }}</b-badge>

                <b-badge
                  v-if="
                    data.item.is_need_sync == 1 &&
                    data.item.is_satusehat_upload == 1
                  "
                  variant="warning"
                  >Perlu Singkronisasi</b-badge
                >
              </template>
              <template #cell(actions)="data">
                <b-button
                  size="sm"
                  class="mr-1 btn-info"
                  v-b-tooltip.hover
                  title="Detail"
                  @click="
                    $router.push({
                      path: '/masters/buildings/detail/' + data.item.id,
                    })
                  "
                >
                  <i class="fas fa-eye px-0"></i>
                </b-button>
                <b-button
                  size="sm"
                  class="mr-1 btn-success"
                  v-b-tooltip.hover
                  title="Edit"
                  @click="
                    $router.push({
                      path: '/masters/buildings/edit/' + data.item.id,
                    })
                  "
                  v-if="btn"
                >
                  <i class="fas fa-edit px-0"></i>
                </b-button>
                <b-button
                  size="sm"
                  class="btn-danger mr-1"
                  v-b-tooltip.hover
                  title="Hapus"
                  @click="deleteData(data.item.id)"
                  v-if="btn && data.item.is_ss_default == 0"
                >
                  <i class="fas fa-trash px-0"></i>
                </b-button>
                <!-- <b-button
                  size="sm"
                  class="btn-success btn-satusehat"
                  :class="data.item.is_need_sync == 1 ? 'pr-1' : ''"
                  v-b-tooltip.hover
                  title="Sinkronisasi Satu Sehat"
                  v-if="btn"
                  @click="syncSatuSehat(data.item)"
                >
                  <i class="fas fa-sync px-0"></i>
                  <sup
                    ><i
                      v-if="data.item.is_need_sync == 1"
                      class="fas fa-circle text-danger fa-xs px-0"
                    ></i
                  ></sup>
                </b-button> -->
              </template>
            </b-table>
            <b-pagination
              v-if="items.length != 0"
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              aria-controls="my-table"
              @page-click="pageOnClick"
              class="mt-4"
            ></b-pagination>
          </template>
        </Card>
      </div>
    </div>
  </div>
</template>

<script>
import Card from "@/view/content/Card.vue";
import { debounce } from "@/core/modules/Helper.js";
import module from "@/core/modules/CrudModule.js";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { getSsOrganizationId } from "@/core/services/jwt.service.js";
import moduleBridge from "@/core/modules/CrudModuleBridge.js";

export default {
  name: "List",

  components: {
    Card,
  },

  data() {
    return {
      // Filter
      filter: {
        name: "",
      },
      // Pagination
      perPage: 20,
      currentPage: 1,
      totalRows: 1,
      // Table Head
      fields: [
        {
          key: "number",
          label: "#",
          sortable: true,
        },
        {
          key: "name",
          label: "Nama",
          sortable: true,
        },
        {
          key: "description",
          label: "Deskripsi",
          sortable: true,
        },
        {
          key: "is_satusehat_upload",
          label: "Satu Sehat",
          sortable: true,
        },
        { key: "actions", label: "Aksi" },
      ],
      // Table Items
      items: [],
      // user access
      btn: false,
      // satu sehat
      satusehatForm: {
        resourceType: "Location",
        status: "active",
        name: "",
        description: "",
        mode: "instance",
        address: {
          use: "work",
          line: [""],
          city: "Jakarta",
          country: "ID",
        },
        physicalType: {
          coding: [
            {
              system:
                "http://terminology.hl7.org/CodeSystem/location-physical-type",
              code: "ro",
              display: "Room",
            },
          ],
        },
      },
      satusehatEditForm: {
        resourceType: "Location",
        id: "",
        name: "",
        description: "",
        mode: "instance",
        address: {
          use: "work",
          line: [""],
          city: "Jakarta",
          country: "ID",
        },
        physicalType: {
          coding: [
            {
              system:
                "http://terminology.hl7.org/CodeSystem/location-physical-type",
              code: "ro",
              display: "Room",
            },
          ],
        },
        managingOrganization: {
          reference: "Organization/{{Org_id}}",
        },
      },
    };
  },

  methods: {
    pageOnClick(evt, page) {
      evt.preventDefault();
      this.currentPage = page;
      this.pagination();
    },

    async pagination() {
      let filterParams = `&name=${this.filter.name}`;
      let response = await module.paginate(
        "buildings",
        `?page=${this.currentPage}&page_size=${this.perPage}${filterParams}`
      );

      let pagination = response.meta.pagination;
      this.totalRows = pagination.total;

      let a, b;
      b = this.perPage * (this.currentPage - 1) + 1;
      for (a = 0; a < response.data.length; a++) {
        response.data[a].number = b + a;
      }

      this.items = response.data;
    },

    filterByName() {
      debounce(() => {
        this.pagination();
      }, 500);
    },

    async deleteData(id) {
      // Delete Data
      let result = await module.delete("buildings/" + id);
      // If Deleted
      if (result) {
        this.pagination();
      }
    },

    setActiveButton() {
      let access_right_user = window.localStorage.getItem(
        "access_right_display"
      );
      let access_right = JSON.parse(access_right_user);

      let a;
      for (a = 0; a < access_right.length; a++) {
        if (access_right[a] == "1802") {
          this.btn = true;
        }
      }
    },
    syncSatuSehat(item) {
      if (item.is_satusehat_upload == 0) {
        this.uploadSatuSehat(item);
      } else {
        this.updateSatuSehat(item);
      }
    },
    async uploadSatuSehat(item) {
      console.log("item", item);
      console.log("this.currentSsOrganizationId;", getSsOrganizationId());

      this.satusehatForm.name = item.name;
      this.satusehatForm.description = item.description;
      this.satusehatForm.address.line[0] =
        "Jl. Kramat 6 No.11, RT.2/RW.1, Kenari, Kec. Senen, Kota Jakarta Pusat, Daerah Khusus Ibukota Jakarta 10430";
      let form = {
        data: this.satusehatForm,
      };
      // // Make Request
      let response = await moduleBridge.submit(form, "location");
      // Check Response
      if (response.state == "error") {
        // Validation Error
        Swal.fire({
          title: "Gagal",
          text: "Gagal mengirim data ke satu sehat",
          icon: "error",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
      } else {
        // Success
        Swal.fire(response.success.title, response.success.message, "success");
        console.log("response disini", response);

        if (response.success.status == "success") {
          this.updateDepartments(item, response.success.data);
        }
      }
    },
    async updateSatuSehat(item) {
      console.log("item", item);
      console.log("this.currentSsOrganizationId;", getSsOrganizationId());

      this.satusehatEditForm.id = item.ss_location_id;
      this.satusehatEditForm.name = item.name;
      this.satusehatEditForm.description = item.description;
      this.satusehatEditForm.address.line[0] =
        "Jl. Kramat 6 No.11, RT.2/RW.1, Kenari, Kec. Senen, Kota Jakarta Pusat, Daerah Khusus Ibukota Jakarta 10430";
      this.satusehatEditForm.managingOrganization.reference = `Organization/${getSsOrganizationId()}`;
      let form = {
        data: this.satusehatEditForm,
        _method: "put",
      };
      // // Make Request
      let response = await moduleBridge.submit(
        form,
        `location/${item.ss_location_id}`
      );
      // Check Response
      if (response.state == "error") {
        // Validation Error
        Swal.fire({
          title: "Gagal",
          text: "Gagal mengirim data ke satu sehat",
          icon: "error",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
      } else {
        // Success
        Swal.fire(response.success.title, response.success.message, "success");
        console.log("response disini", response);

        if (response.success.status == "success") {
          item.is_need_sync = 0;
          this.updateDepartments(item, response.success.data);
        }
      }
    },
    async updateDepartments(item, data) {
      let form = item;
      form.is_satusehat_upload = 1;
      form.ss_location_id = data.id;
      form["_method"] = "put";
      let response = await module.submitWithoutAlert(
        form,
        `buildings/${item.id}`
      );
      // Check Response
      if (response.state == "error") {
        // Validation Error
      } else {
        // Success
        console.log("response disini", response);
        this.pagination();
      }
    },
  },

  mounted() {
    // BreadCrumb
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Gedung" }]);
    // Get Data
    this.pagination();
    this.setActiveButton();
  },
};
</script>
